<script>
import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Resend Code",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      email: "",
      password: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      invalidPassword : "Your account or password is incorrect.",
      statePassword : true,
      invalidEmail : "Your account or Email is incorrect.",
      stateEmail : true,
      passViewState : "password",
      loading : false,
      landing : process.env.VUE_APP_LANDING
    };
  },
  mounted() {
    // this.$ga.page('/resend')
    this.$ga.event('resend-page', 'actionx', 'labelx', 1)
  },
  methods: {
    ...authMethods,
    viewPass(){
      this.passViewState = this.passViewState == "password" ? "text" : "password";
    },
    landingPage(){
      window.open(this.landing,"_self")
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.loading = true;
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      this.isAuthError = false;
      return (
        this.resendCode({
          email: this.email
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = true;
            this.authError = token;
            this.stateEmail = true;
            this.loading = false;
            this.$router.push(
              this.$route.query.redirectFrom || { name: "Verify" }
            );
            
          })
          .catch(error => {
            this.loading = false;
            this.tryingToLogIn = false;
            this.invalidEmail = error ? error : "";
            this.stateEmail = false;
            this.isAuthError = false;
          })
      );
    }
  }
};
</script>

<template>
  <Layout>
    <div class="d-flex justify-content-center align-items-center">
      <div class="mx-4 px-4">
          <img src="@/assets/images/signup.png"  alt class="cursor-pointer px-2 img-fluid" />
      </div>
      <div class="mx-4 px-4"></div>
      <div class="row justify-content-center mt-4 mb-4 pb-4">
        <div class="mt-4">
          <div class="card overflow-hidden card-no-shadow">
            <h1 class="text-center h1-title">Resend Code</h1>
            <p class="default-text-style">Enter your registered email, to get the verification code again.</p>
            <div class="card-body pt-4">
              <b-alert v-model="isAuthError" variant="success" class="mt-3" dismissible>{{authError}}</b-alert>
              <b-form class="p-2" @submit.prevent="tryToLogIn">
                <b-form-group id="input-group-1" class="py-2" :invalid-feedback="invalidEmail" :state="stateEmail"   label-class="label-input"   label-for="input-1">
                  <b-form-input id="input-1" class="login-input-style minW" v-model="email" type="email" placeholder="Email Address"></b-form-input>
                </b-form-group>
                <div class="mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <button class="btn base-btn btn-block">
                      <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                      <span class="default-text-style">Send</span>
                      </button>
                </div>
              </b-form>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <!-- end row -->
        </div>
        <!-- end col -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
